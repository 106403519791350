<p class="ui-heading-2-bold--s">Booking Request</p>

<div class="search">
  <rds-search
    placeholder="Search Room, Bookly ID or event name"
    (search)="onSearch($event)"
    (clear)="onClear()"
    autoSearch="true"
    size="m"></rds-search>
</div>

<rds-tabs>
  <rds-tab label="Awaiting Approval">
    @if (!isRoomBookingDataLoading()) {
      <bad-room-bookings-table
        [roomBooking]="pendingRequests()"
        (rowClicked)="clickRowHandler($event)"></bad-room-bookings-table>
    } @else {
      <div class="loader">
        <rds-progress-spinner></rds-progress-spinner>
      </div>
    }
  </rds-tab>
  <rds-tab label="Approved">
    @if (!isRoomBookingDataLoading()) {
      <bad-room-bookings-table
        [roomBooking]="approvedRequests()"
        (rowClicked)="clickRowHandler($event)"></bad-room-bookings-table>
    } @else {
      <div class="loader">
        <rds-progress-spinner></rds-progress-spinner>
      </div>
    }
  </rds-tab>
  <rds-tab label="All Bookings">
    @if (!isRoomBookingDataLoading()) {
      <bad-room-bookings-table
        [roomBooking]="allBookings()"
        (rowClicked)="clickRowHandler($event)"></bad-room-bookings-table>
    } @else {
      <div class="loader">
        <rds-progress-spinner></rds-progress-spinner>
      </div>
    }
  </rds-tab>
</rds-tabs>
