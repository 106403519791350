import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  RdsDialogService,
  RdsProgressSpinnerModule,
  RdsSearchEvent,
  RdsSearchModule,
  RdsTabModule,
} from '@rds/angular-components';
import { take } from 'rxjs';

import { RoomBookingModel } from '@bookly/shared';

import { RoomBookingsTableComponent } from '../../components/room-bookings-table/room-bookings-table.component';
import { BookingRequestService } from '../../services/booking-request.service';
import { BookingRequestDetailsComponent } from '../booking-request-details/booking-request-details.component';

@Component({
  selector: 'bad-booking-request',
  templateUrl: './booking-request.component.html',
  styleUrls: ['./booking-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RdsSearchModule,
    RdsTabModule,
    RoomBookingsTableComponent,
    RdsProgressSpinnerModule,
  ],
})
export class BookingRequestComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  readonly #dialogService = inject(RdsDialogService);
  readonly #bookingRequestService = inject(BookingRequestService);
  readonly #route = inject(ActivatedRoute);
  readonly #location = inject(Location);
  protected readonly pendingRequests =
    this.#bookingRequestService.pendingRequests;
  protected readonly approvedRequests =
    this.#bookingRequestService.approveBookings;
  protected readonly allBookings = this.#bookingRequestService.allBookings;
  protected readonly isRoomBookingDataLoading =
    this.#bookingRequestService.isRoomBookingDataLoading;

  public ngOnInit() {
    this.#bookingRequestService.getRoomBookings().subscribe();
  }

  public ngAfterViewInit() {
    const bookingId = this.#route.snapshot.queryParamMap.get('bookingId');
    const roomDexId = this.#route.snapshot.queryParamMap.get('roomDexId');
    if (bookingId && roomDexId) {
      this.clickRowHandler(
        {
          id: +bookingId,
          roomDexId: roomDexId,
        } as RoomBookingModel,
        false
      );
    }
  }

  public ngOnDestroy() {
    this.#bookingRequestService.clearSearchQuery();
  }

  protected clickRowHandler(
    data: RoomBookingModel,
    replaceParams: boolean = true
  ) {
    if (replaceParams) {
      const params = new HttpParams().appendAll({
        bookingId: data.id,
        roomDexId: data.roomDexId,
      });
      this.#location.replaceState(location.pathname, params.toString());
    }
    const dialogRef = this.#dialogService.open(BookingRequestDetailsComponent, {
      disableClose: true,
      size: 'xl',
      maxHeight: '100%',
      data: {
        bookingId: data.id,
        roomDexId: data.roomDexId,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.#location.replaceState(location.pathname, '');
      });
  }

  onSearch(searchEvent: RdsSearchEvent) {
    this.#bookingRequestService.searchRoomBookings(searchEvent.query);
  }

  onClear() {
    this.#bookingRequestService.resetSearchRoomBookings();
  }
}
