import { NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import {
  RdsAvatarComponent,
  RdsAvatarImageDirective,
  RdsButtonModule,
  RdsCustomIconComponent,
  RdsDividerModule,
  RdsDropdownDirective,
  RdsDropdownModule,
  RdsHeaderModule,
  RdsIconComponent,
  RdsProgressSpinnerComponent,
  RdsSidenavModule,
} from '@rds/angular-components';

import {
  AuthService,
  DestinationFrontendEnum,
  NotificationsDropdownComponent,
  NotificationsService,
  environment,
} from '@bookly/shared';

@Component({
  selector: 'bad-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RdsHeaderModule,
    RdsDividerModule,
    RdsDropdownModule,
    RdsAvatarComponent,
    RdsAvatarImageDirective,
    RdsButtonModule,
    RdsSidenavModule,
    RouterLinkActive,
    RouterLink,
    RdsIconComponent,
    RdsCustomIconComponent,
    RouterOutlet,
    NgOptimizedImage,
    NotificationsDropdownComponent,
    RdsProgressSpinnerComponent,
  ],
})
export class DashboardComponent implements OnInit {
  readonly #authService = inject(AuthService);
  readonly #notificationsService = inject(NotificationsService);
  protected readonly user = this.#authService.user;
  protected readonly development = environment.shared.development;
  protected readonly isLoading = this.#authService.isLoginPending;

  @ViewChild(RdsDropdownDirective)
  userMenuDropdown!: RdsDropdownDirective;

  ngOnInit() {
    this.#notificationsService
      .getNotifications(DestinationFrontendEnum.Admin)
      .subscribe();
  }

  protected logout() {
    this.userMenuDropdown.hide();
    void this.#authService.logout();
  }
}
