<table rds-table [dataSource]="dataSource" rdsSort>
  <ng-container rdsColumnDef="id">
    <th rds-header-cell *rdsHeaderCellDef rds-sort-header>Bookly ID</th>
    <td rds-cell *rdsCellDef="let element">{{ element.id }}</td>
  </ng-container>

  <ng-container rdsColumnDef="roomDescription">
    <th rds-header-cell *rdsHeaderCellDef>Description</th>
    <td rds-cell *rdsCellDef="let element">
      <p class="ui-body-m-bold">{{ element.eventName }}</p>
      <p>{{ element.roomDescription }}</p>
    </td>
  </ng-container>

  <ng-container rdsColumnDef="status">
    <th rds-header-cell *rdsHeaderCellDef rds-sort-header>Status</th>
    <td rds-cell *rdsCellDef="let element">
      <rds-badge
        size="m"
        type="minimal"
        [color]="element.status.color"
        [label]="element.status.text"></rds-badge>
    </td>
  </ng-container>

  <ng-container rdsColumnDef="startTime">
    <th rds-header-cell *rdsHeaderCellDef rds-sort-header>Meeting Date&Time</th>
    <td rds-cell *rdsCellDef="let element">
      <p>{{ element.startDate | eventDateTime: element.endDate }}</p>
    </td>
  </ng-container>

  <ng-container rdsColumnDef="requester">
    <th rds-header-cell *rdsHeaderCellDef rds-sort-header>Requester/Date</th>
    <td rds-cell *rdsCellDef="let element">
      <p>
        {{ element.requester.fullName }}
      </p>
      <p>{{ element.createTime }}</p>
    </td>
  </ng-container>

  <ng-container rdsColumnDef="actions">
    <th rds-header-cell *rdsHeaderCellDef rds-sort-header></th>
    <td rds-cell *rdsCellDef="let element">
      <button rds-icon-button size="m">
        <rds-icon namespace="filled" icon="more_vertical"></rds-icon>
      </button>
    </td>
  </ng-container>

  <tr rds-header-row *rdsHeaderRowDef="displayedColumns"></tr>
  <tr
    rds-row
    *rdsRowDef="let row; columns: displayedColumns"
    (click)="clickRowHandler(row)"></tr>
</table>
